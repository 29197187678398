html {
  font-size: 16px; /* Base font size for rem units */
}

@media (max-width: 1200px) {
  html {
    font-size: 14px; /* Scale down for medium screens */
  }
}

@media (max-width: 768px) {
  html {
    font-size: 12px; /* Scale down further for small screens */
  }
}

* {
  -webkit-scrollbar: none;
  scrollbar-width: none;
  scrollbar-color: #333 #fff;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.rdrDateDisplayWrapper{
    margin-bottom: 0.3125rem; /* 5px */
}

.showMore-span{
    width: fit-content;
    font-size: 0.625rem; /* 10px */
    padding: 0.125rem; /* 2px */
    filter: brightness(85%);
}

.showMore-span:hover{
    cursor: pointer;
    filter: brightness(100%);
}

.dropdown.visible {
  animation: fadeIn 0.5s forwards;
}

div.dropdown.hidden {
    animation: fadeOut 0.5s forwards;
}

.metrics-load.visible{
    animation: metricsFadeIn 0.5s forwards;
}
.metrics-load.hidden{
    animation: metricsFadeOut 0.5s forwards;
}

.dropdown-container{
    position: relative;
}

.dropdown-content {
    background-color: #f9f9f9;
    width: 17.5rem; /* 280px */
    max-height: 18.75rem; /* 300px */
    /*overflow: auto;*/
    box-shadow: 0px 0.5rem 1rem 0px rgba(0,0,0,0.2); /* 0px 8px 16px */
    z-index: 9999; 
    position: absolute; 
    top: 100%;
    left: 0;
  }

  .list-container{
    background-color: #f9f9f9;
    overflow: auto;
  }
  
  .dropdown-content input[type="text"] {
    padding: 0.3125rem 0.5rem; /* 5px 8px */
    margin: 0.5rem 0.5rem 0 0.5rem; /* 8px 8px 0 8px */
    display: inline-block;
    border: none;
    box-sizing: border-box;
    width: 95%;
  }
  
  .dropdown-content button {
    background-color: #f9f9f9;
    color: black;
    padding: 0.625rem; /* 10px */
    border: none;
    cursor: pointer;
    width: 48%;
  }
  
  .dropdown-content button:hover {
    background-color: #e6e6e6;
  }

  .dropdown-content .item:hover{
    background-color: #e6e6e6;
  }
  
  .dropdown-content div {
    padding: 0.25rem 0.3125rem; /* 4px 5px */
    cursor: pointer;
  }
  
  .dropdown-content div:hover {
    background-color: #f1f1f1;
  }

  section[aria-label="Unexposed Visit Uplift"].MuiBox-root.css-4zufgp {
    width: 95%;
  }
  section[aria-label="Exposed Visit Uplift"].MuiBox-root.css-4zufgp {
    width: 95%;
  }

  .timeseries-container{
    /*transform: translate(12rem, 25rem);*/ /* 192px, 400px */
    display: flex;
    flex-direction: row;
    height: 15.9375rem; /* 255px */
  }

  .parent-container{
    border: 0.0625rem solid darkgray; /* 1px */
    background-color: white;
    border-radius: 0.625rem; /* 10px */
  }

  .timeseries-toggle{
    width: 25rem; /* 400px */
    height: 3.125rem; /* 50px */
    border-radius: 0.625rem; /* 10px */
    background-color: white;
    padding: auto;
    text-align: center;
    font-size: 1.25rem; /* 20px */
    font-weight: bold;
    color: black;
    cursor: pointer;
  }

  .loader{
    color: #2C3032;
  }

  .rotating-icon img {
    animation: rotate 1.5s linear infinite;
  }

  .dropdown-content {
    position: relative;
  }
  
  .filter-search-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem; /* 10px */
  }
  
  .filter-button {
    width: 2.5rem; /* 40px */
    height: 2.5rem; /* 40px */
  }
  
  .filter-options-wrapper {
    position: absolute;
    top: 9.375%; /* 15% */
    left: 0;
    width: 46.875%; /* 75% */
    background-color: white;
    z-index: 10;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.2); /* 0 2px 8px */
    padding: 0.3125rem; /* 5px */
  }
  
  .filter-options {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .filter-option{
    list-style-type: none;
    font-size: 0.75rem; /* 12px */
    padding: 0.625rem; /* 10px */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .filter-title{
    list-style-type: none;
    padding: 0.625rem; /* 10px */
    color: black;
    font-size: 0.625rem; /* 10px */
    font-weight: bold;
    font-style: oblique;
  }

  .filter-option:hover{
    background-color: #e6e6e6;
    cursor: pointer;
  }
  
  .filter-options li {
    list-style-type: none;
    cursor: pointer;
    padding: 0.3125rem; /* 5px */
  }
  
  .filter-options li:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-container {
    width: 17.5rem; /* 280px */
  }
  
  .list-container {
    margin-top: 0.625rem; /* 10px */
  }

  .custom-slider .rc-slider-track {
    background-color: #7CCBEE;
  }
  
  .custom-slider .rc-slider-handle {
    border-color: #7CCBEE;
    background-color: #7CCBEE;
  }

  .rc-slider{
    cursor: pointer;
  }

  .custom-slider .rec-slider-step {
    border-color: #7CCBEE;
    background-color: #7CCBEE;
  }
  
  @keyframes rotate {
    100% {
      transform: rotateY(360deg);
    }
  }
  @keyframes fadeOut {
      0% {
          opacity: 1;
          transform: translateY(0);
      }
      99%{
          opacity: 0.01;
          transform: translateY(-31.25rem); /* -500px */
      }
      100% {
          opacity: 0;
          transform: translateY(-43.75rem); /* -700px */
      }
  }

  @keyframes metricsFadeOut {
      0% {
          transform: translateY(0);
      }
      100% {
          transform: translateY(-31.25rem); /* -500px */
      }
  }

  @keyframes fadeIn {
      0% {
          opacity: 0;
          transform: translateY(-31.25rem); /* -500px */
      }
      100% {
          opacity: 1;
          transform: translateY(0);
          display: flex;
          flex-direction: column;
      }
  }

  @keyframes metricsFadeIn {
      0% {
          transform: translateY(-31.25rem); /* -500px */
      }
      100% {
          transform: translateY(0);
      }
  }